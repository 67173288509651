import { default as _91_46_46_46slug_93HOHVAzhyYAMeta } from "D:/dev_web/pages/[...slug].vue?macro=true";
import { default as account_45editjvpVNbebCKMeta } from "D:/dev_web/pages/account-edit.vue?macro=true";
import { default as accountrmBvQZXhysMeta } from "D:/dev_web/pages/account.vue?macro=true";
import { default as cartKRtLWkbkHBMeta } from "D:/dev_web/pages/cart.vue?macro=true";
import { default as change_45passwordzdLr4ymAcvMeta } from "D:/dev_web/pages/change-password.vue?macro=true";
import { default as checkout_45balance0LczlNRlKqMeta } from "D:/dev_web/pages/checkout-balance.vue?macro=true";
import { default as checkout_45paypal_45successxiutrNBqZsMeta } from "D:/dev_web/pages/checkout-paypal-success.vue?macro=true";
import { default as checkout_45paypalE2Iyehq7MoMeta } from "D:/dev_web/pages/checkout-paypal.vue?macro=true";
import { default as checkout_45successxvYGO91f0wMeta } from "D:/dev_web/pages/checkout-success.vue?macro=true";
import { default as checkoutfQoLXMNsolMeta } from "D:/dev_web/pages/checkout.vue?macro=true";
import { default as forgot_45password4pJuAa2ssFMeta } from "D:/dev_web/pages/forgot-password.vue?macro=true";
import { default as indexhdP9oJyh1NMeta } from "D:/dev_web/pages/index.vue?macro=true";
import { default as investments2BZPkS0vVMMeta } from "D:/dev_web/pages/investments.vue?macro=true";
import { default as login_45qrcodedi0HwdXj5ZMeta } from "D:/dev_web/pages/login-qrcode.vue?macro=true";
import { default as loginoGUTr01GGmMeta } from "D:/dev_web/pages/login.vue?macro=true";
import { default as order_45checkoutt7aFP6dU6OMeta } from "D:/dev_web/pages/order-checkout.vue?macro=true";
import { default as _91id_93aS0Ckj0sFiMeta } from "D:/dev_web/pages/order-qrcode/[id].vue?macro=true";
import { default as order_45resultpABTjhdqjGMeta } from "D:/dev_web/pages/order-result.vue?macro=true";
import { default as _91id_939MDWzTqNpfMeta } from "D:/dev_web/pages/order/[id].vue?macro=true";
import { default as ordersQK4Azw2LEfMeta } from "D:/dev_web/pages/orders.vue?macro=true";
import { default as recharge_45logXXuBSCDOiWMeta } from "D:/dev_web/pages/recharge-log.vue?macro=true";
import { default as rechargeUMHkldNVaJMeta } from "D:/dev_web/pages/recharge.vue?macro=true";
import { default as register0VfGnzk9qVMeta } from "D:/dev_web/pages/register.vue?macro=true";
import { default as reset_45passwordOUzA4hn5xlMeta } from "D:/dev_web/pages/reset-password.vue?macro=true";
import { default as _91_46_46_46slug_93x9JDj0h6hSMeta } from "D:/dev_web/pages/shop/[...slug].vue?macro=true";
import { default as transfer_45logi0KJubykB0Meta } from "D:/dev_web/pages/transfer-log.vue?macro=true";
import { default as transfer3PtB9qZVgGMeta } from "D:/dev_web/pages/transfer.vue?macro=true";
export default [
  {
    name: "slug___en___default",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93HOHVAzhyYAMeta || {},
    component: () => import("D:/dev_web/pages/[...slug].vue")
  },
  {
    name: "slug___en",
    path: "/en/:slug(.*)*",
    meta: _91_46_46_46slug_93HOHVAzhyYAMeta || {},
    component: () => import("D:/dev_web/pages/[...slug].vue")
  },
  {
    name: "slug___vi",
    path: "/vi/:slug(.*)*",
    meta: _91_46_46_46slug_93HOHVAzhyYAMeta || {},
    component: () => import("D:/dev_web/pages/[...slug].vue")
  },
  {
    name: "slug___cn",
    path: "/cn/:slug(.*)*",
    meta: _91_46_46_46slug_93HOHVAzhyYAMeta || {},
    component: () => import("D:/dev_web/pages/[...slug].vue")
  },
  {
    name: "account-edit___en___default",
    path: "/account-edit",
    meta: account_45editjvpVNbebCKMeta || {},
    component: () => import("D:/dev_web/pages/account-edit.vue")
  },
  {
    name: "account-edit___en",
    path: "/en/account-edit",
    meta: account_45editjvpVNbebCKMeta || {},
    component: () => import("D:/dev_web/pages/account-edit.vue")
  },
  {
    name: "account-edit___vi",
    path: "/vi/account-edit",
    meta: account_45editjvpVNbebCKMeta || {},
    component: () => import("D:/dev_web/pages/account-edit.vue")
  },
  {
    name: "account-edit___cn",
    path: "/cn/account-edit",
    meta: account_45editjvpVNbebCKMeta || {},
    component: () => import("D:/dev_web/pages/account-edit.vue")
  },
  {
    name: "account___en___default",
    path: "/account",
    meta: accountrmBvQZXhysMeta || {},
    component: () => import("D:/dev_web/pages/account.vue")
  },
  {
    name: "account___en",
    path: "/en/account",
    meta: accountrmBvQZXhysMeta || {},
    component: () => import("D:/dev_web/pages/account.vue")
  },
  {
    name: "account___vi",
    path: "/vi/account",
    meta: accountrmBvQZXhysMeta || {},
    component: () => import("D:/dev_web/pages/account.vue")
  },
  {
    name: "account___cn",
    path: "/cn/account",
    meta: accountrmBvQZXhysMeta || {},
    component: () => import("D:/dev_web/pages/account.vue")
  },
  {
    name: "cart___en___default",
    path: "/cart",
    meta: cartKRtLWkbkHBMeta || {},
    component: () => import("D:/dev_web/pages/cart.vue")
  },
  {
    name: "cart___en",
    path: "/en/cart",
    meta: cartKRtLWkbkHBMeta || {},
    component: () => import("D:/dev_web/pages/cart.vue")
  },
  {
    name: "cart___vi",
    path: "/vi/cart",
    meta: cartKRtLWkbkHBMeta || {},
    component: () => import("D:/dev_web/pages/cart.vue")
  },
  {
    name: "cart___cn",
    path: "/cn/cart",
    meta: cartKRtLWkbkHBMeta || {},
    component: () => import("D:/dev_web/pages/cart.vue")
  },
  {
    name: "change-password___en___default",
    path: "/change-password",
    meta: change_45passwordzdLr4ymAcvMeta || {},
    component: () => import("D:/dev_web/pages/change-password.vue")
  },
  {
    name: "change-password___en",
    path: "/en/change-password",
    meta: change_45passwordzdLr4ymAcvMeta || {},
    component: () => import("D:/dev_web/pages/change-password.vue")
  },
  {
    name: "change-password___vi",
    path: "/vi/change-password",
    meta: change_45passwordzdLr4ymAcvMeta || {},
    component: () => import("D:/dev_web/pages/change-password.vue")
  },
  {
    name: "change-password___cn",
    path: "/cn/change-password",
    meta: change_45passwordzdLr4ymAcvMeta || {},
    component: () => import("D:/dev_web/pages/change-password.vue")
  },
  {
    name: "checkout-balance___en___default",
    path: "/checkout-balance",
    meta: checkout_45balance0LczlNRlKqMeta || {},
    component: () => import("D:/dev_web/pages/checkout-balance.vue")
  },
  {
    name: "checkout-balance___en",
    path: "/en/checkout-balance",
    meta: checkout_45balance0LczlNRlKqMeta || {},
    component: () => import("D:/dev_web/pages/checkout-balance.vue")
  },
  {
    name: "checkout-balance___vi",
    path: "/vi/checkout-balance",
    meta: checkout_45balance0LczlNRlKqMeta || {},
    component: () => import("D:/dev_web/pages/checkout-balance.vue")
  },
  {
    name: "checkout-balance___cn",
    path: "/cn/checkout-balance",
    meta: checkout_45balance0LczlNRlKqMeta || {},
    component: () => import("D:/dev_web/pages/checkout-balance.vue")
  },
  {
    name: "checkout-paypal-success___en___default",
    path: "/checkout-paypal-success",
    meta: checkout_45paypal_45successxiutrNBqZsMeta || {},
    component: () => import("D:/dev_web/pages/checkout-paypal-success.vue")
  },
  {
    name: "checkout-paypal-success___en",
    path: "/en/checkout-paypal-success",
    meta: checkout_45paypal_45successxiutrNBqZsMeta || {},
    component: () => import("D:/dev_web/pages/checkout-paypal-success.vue")
  },
  {
    name: "checkout-paypal-success___vi",
    path: "/vi/checkout-paypal-success",
    meta: checkout_45paypal_45successxiutrNBqZsMeta || {},
    component: () => import("D:/dev_web/pages/checkout-paypal-success.vue")
  },
  {
    name: "checkout-paypal-success___cn",
    path: "/cn/checkout-paypal-success",
    meta: checkout_45paypal_45successxiutrNBqZsMeta || {},
    component: () => import("D:/dev_web/pages/checkout-paypal-success.vue")
  },
  {
    name: "checkout-paypal___en___default",
    path: "/checkout-paypal",
    meta: checkout_45paypalE2Iyehq7MoMeta || {},
    component: () => import("D:/dev_web/pages/checkout-paypal.vue")
  },
  {
    name: "checkout-paypal___en",
    path: "/en/checkout-paypal",
    meta: checkout_45paypalE2Iyehq7MoMeta || {},
    component: () => import("D:/dev_web/pages/checkout-paypal.vue")
  },
  {
    name: "checkout-paypal___vi",
    path: "/vi/checkout-paypal",
    meta: checkout_45paypalE2Iyehq7MoMeta || {},
    component: () => import("D:/dev_web/pages/checkout-paypal.vue")
  },
  {
    name: "checkout-paypal___cn",
    path: "/cn/checkout-paypal",
    meta: checkout_45paypalE2Iyehq7MoMeta || {},
    component: () => import("D:/dev_web/pages/checkout-paypal.vue")
  },
  {
    name: "checkout-success___en___default",
    path: "/checkout-success",
    meta: checkout_45successxvYGO91f0wMeta || {},
    component: () => import("D:/dev_web/pages/checkout-success.vue")
  },
  {
    name: "checkout-success___en",
    path: "/en/checkout-success",
    meta: checkout_45successxvYGO91f0wMeta || {},
    component: () => import("D:/dev_web/pages/checkout-success.vue")
  },
  {
    name: "checkout-success___vi",
    path: "/vi/checkout-success",
    meta: checkout_45successxvYGO91f0wMeta || {},
    component: () => import("D:/dev_web/pages/checkout-success.vue")
  },
  {
    name: "checkout-success___cn",
    path: "/cn/checkout-success",
    meta: checkout_45successxvYGO91f0wMeta || {},
    component: () => import("D:/dev_web/pages/checkout-success.vue")
  },
  {
    name: "checkout___en___default",
    path: "/checkout",
    meta: checkoutfQoLXMNsolMeta || {},
    component: () => import("D:/dev_web/pages/checkout.vue")
  },
  {
    name: "checkout___en",
    path: "/en/checkout",
    meta: checkoutfQoLXMNsolMeta || {},
    component: () => import("D:/dev_web/pages/checkout.vue")
  },
  {
    name: "checkout___vi",
    path: "/vi/checkout",
    meta: checkoutfQoLXMNsolMeta || {},
    component: () => import("D:/dev_web/pages/checkout.vue")
  },
  {
    name: "checkout___cn",
    path: "/cn/checkout",
    meta: checkoutfQoLXMNsolMeta || {},
    component: () => import("D:/dev_web/pages/checkout.vue")
  },
  {
    name: "forgot-password___en___default",
    path: "/forgot-password",
    meta: forgot_45password4pJuAa2ssFMeta || {},
    component: () => import("D:/dev_web/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___en",
    path: "/en/forgot-password",
    meta: forgot_45password4pJuAa2ssFMeta || {},
    component: () => import("D:/dev_web/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___vi",
    path: "/vi/forgot-password",
    meta: forgot_45password4pJuAa2ssFMeta || {},
    component: () => import("D:/dev_web/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___cn",
    path: "/cn/forgot-password",
    meta: forgot_45password4pJuAa2ssFMeta || {},
    component: () => import("D:/dev_web/pages/forgot-password.vue")
  },
  {
    name: "index___en___default",
    path: "/",
    meta: indexhdP9oJyh1NMeta || {},
    component: () => import("D:/dev_web/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexhdP9oJyh1NMeta || {},
    component: () => import("D:/dev_web/pages/index.vue")
  },
  {
    name: "index___vi",
    path: "/vi",
    meta: indexhdP9oJyh1NMeta || {},
    component: () => import("D:/dev_web/pages/index.vue")
  },
  {
    name: "index___cn",
    path: "/cn",
    meta: indexhdP9oJyh1NMeta || {},
    component: () => import("D:/dev_web/pages/index.vue")
  },
  {
    name: "investments___en___default",
    path: "/investments",
    meta: investments2BZPkS0vVMMeta || {},
    component: () => import("D:/dev_web/pages/investments.vue")
  },
  {
    name: "investments___en",
    path: "/en/investments",
    meta: investments2BZPkS0vVMMeta || {},
    component: () => import("D:/dev_web/pages/investments.vue")
  },
  {
    name: "investments___vi",
    path: "/vi/investments",
    meta: investments2BZPkS0vVMMeta || {},
    component: () => import("D:/dev_web/pages/investments.vue")
  },
  {
    name: "investments___cn",
    path: "/cn/investments",
    meta: investments2BZPkS0vVMMeta || {},
    component: () => import("D:/dev_web/pages/investments.vue")
  },
  {
    name: "login-qrcode___en___default",
    path: "/login-qrcode",
    meta: login_45qrcodedi0HwdXj5ZMeta || {},
    component: () => import("D:/dev_web/pages/login-qrcode.vue")
  },
  {
    name: "login-qrcode___en",
    path: "/en/login-qrcode",
    meta: login_45qrcodedi0HwdXj5ZMeta || {},
    component: () => import("D:/dev_web/pages/login-qrcode.vue")
  },
  {
    name: "login-qrcode___vi",
    path: "/vi/login-qrcode",
    meta: login_45qrcodedi0HwdXj5ZMeta || {},
    component: () => import("D:/dev_web/pages/login-qrcode.vue")
  },
  {
    name: "login-qrcode___cn",
    path: "/cn/login-qrcode",
    meta: login_45qrcodedi0HwdXj5ZMeta || {},
    component: () => import("D:/dev_web/pages/login-qrcode.vue")
  },
  {
    name: "login___en___default",
    path: "/login",
    meta: loginoGUTr01GGmMeta || {},
    component: () => import("D:/dev_web/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: loginoGUTr01GGmMeta || {},
    component: () => import("D:/dev_web/pages/login.vue")
  },
  {
    name: "login___vi",
    path: "/vi/login",
    meta: loginoGUTr01GGmMeta || {},
    component: () => import("D:/dev_web/pages/login.vue")
  },
  {
    name: "login___cn",
    path: "/cn/login",
    meta: loginoGUTr01GGmMeta || {},
    component: () => import("D:/dev_web/pages/login.vue")
  },
  {
    name: "order-checkout___en___default",
    path: "/order-checkout",
    meta: order_45checkoutt7aFP6dU6OMeta || {},
    component: () => import("D:/dev_web/pages/order-checkout.vue")
  },
  {
    name: "order-checkout___en",
    path: "/en/order-checkout",
    meta: order_45checkoutt7aFP6dU6OMeta || {},
    component: () => import("D:/dev_web/pages/order-checkout.vue")
  },
  {
    name: "order-checkout___vi",
    path: "/vi/order-checkout",
    meta: order_45checkoutt7aFP6dU6OMeta || {},
    component: () => import("D:/dev_web/pages/order-checkout.vue")
  },
  {
    name: "order-checkout___cn",
    path: "/cn/order-checkout",
    meta: order_45checkoutt7aFP6dU6OMeta || {},
    component: () => import("D:/dev_web/pages/order-checkout.vue")
  },
  {
    name: "order-qrcode-id___en___default",
    path: "/order-qrcode/:id()",
    meta: _91id_93aS0Ckj0sFiMeta || {},
    component: () => import("D:/dev_web/pages/order-qrcode/[id].vue")
  },
  {
    name: "order-qrcode-id___en",
    path: "/en/order-qrcode/:id()",
    meta: _91id_93aS0Ckj0sFiMeta || {},
    component: () => import("D:/dev_web/pages/order-qrcode/[id].vue")
  },
  {
    name: "order-qrcode-id___vi",
    path: "/vi/order-qrcode/:id()",
    meta: _91id_93aS0Ckj0sFiMeta || {},
    component: () => import("D:/dev_web/pages/order-qrcode/[id].vue")
  },
  {
    name: "order-qrcode-id___cn",
    path: "/cn/order-qrcode/:id()",
    meta: _91id_93aS0Ckj0sFiMeta || {},
    component: () => import("D:/dev_web/pages/order-qrcode/[id].vue")
  },
  {
    name: "order-result___en___default",
    path: "/order-result",
    meta: order_45resultpABTjhdqjGMeta || {},
    component: () => import("D:/dev_web/pages/order-result.vue")
  },
  {
    name: "order-result___en",
    path: "/en/order-result",
    meta: order_45resultpABTjhdqjGMeta || {},
    component: () => import("D:/dev_web/pages/order-result.vue")
  },
  {
    name: "order-result___vi",
    path: "/vi/order-result",
    meta: order_45resultpABTjhdqjGMeta || {},
    component: () => import("D:/dev_web/pages/order-result.vue")
  },
  {
    name: "order-result___cn",
    path: "/cn/order-result",
    meta: order_45resultpABTjhdqjGMeta || {},
    component: () => import("D:/dev_web/pages/order-result.vue")
  },
  {
    name: "order-id___en___default",
    path: "/order/:id()",
    meta: _91id_939MDWzTqNpfMeta || {},
    component: () => import("D:/dev_web/pages/order/[id].vue")
  },
  {
    name: "order-id___en",
    path: "/en/order/:id()",
    meta: _91id_939MDWzTqNpfMeta || {},
    component: () => import("D:/dev_web/pages/order/[id].vue")
  },
  {
    name: "order-id___vi",
    path: "/vi/order/:id()",
    meta: _91id_939MDWzTqNpfMeta || {},
    component: () => import("D:/dev_web/pages/order/[id].vue")
  },
  {
    name: "order-id___cn",
    path: "/cn/order/:id()",
    meta: _91id_939MDWzTqNpfMeta || {},
    component: () => import("D:/dev_web/pages/order/[id].vue")
  },
  {
    name: "orders___en___default",
    path: "/orders",
    meta: ordersQK4Azw2LEfMeta || {},
    component: () => import("D:/dev_web/pages/orders.vue")
  },
  {
    name: "orders___en",
    path: "/en/orders",
    meta: ordersQK4Azw2LEfMeta || {},
    component: () => import("D:/dev_web/pages/orders.vue")
  },
  {
    name: "orders___vi",
    path: "/vi/orders",
    meta: ordersQK4Azw2LEfMeta || {},
    component: () => import("D:/dev_web/pages/orders.vue")
  },
  {
    name: "orders___cn",
    path: "/cn/orders",
    meta: ordersQK4Azw2LEfMeta || {},
    component: () => import("D:/dev_web/pages/orders.vue")
  },
  {
    name: "recharge-log___en___default",
    path: "/recharge-log",
    meta: recharge_45logXXuBSCDOiWMeta || {},
    component: () => import("D:/dev_web/pages/recharge-log.vue")
  },
  {
    name: "recharge-log___en",
    path: "/en/recharge-log",
    meta: recharge_45logXXuBSCDOiWMeta || {},
    component: () => import("D:/dev_web/pages/recharge-log.vue")
  },
  {
    name: "recharge-log___vi",
    path: "/vi/recharge-log",
    meta: recharge_45logXXuBSCDOiWMeta || {},
    component: () => import("D:/dev_web/pages/recharge-log.vue")
  },
  {
    name: "recharge-log___cn",
    path: "/cn/recharge-log",
    meta: recharge_45logXXuBSCDOiWMeta || {},
    component: () => import("D:/dev_web/pages/recharge-log.vue")
  },
  {
    name: "recharge___en___default",
    path: "/recharge",
    meta: rechargeUMHkldNVaJMeta || {},
    component: () => import("D:/dev_web/pages/recharge.vue")
  },
  {
    name: "recharge___en",
    path: "/en/recharge",
    meta: rechargeUMHkldNVaJMeta || {},
    component: () => import("D:/dev_web/pages/recharge.vue")
  },
  {
    name: "recharge___vi",
    path: "/vi/recharge",
    meta: rechargeUMHkldNVaJMeta || {},
    component: () => import("D:/dev_web/pages/recharge.vue")
  },
  {
    name: "recharge___cn",
    path: "/cn/recharge",
    meta: rechargeUMHkldNVaJMeta || {},
    component: () => import("D:/dev_web/pages/recharge.vue")
  },
  {
    name: "register___en___default",
    path: "/register",
    meta: register0VfGnzk9qVMeta || {},
    component: () => import("D:/dev_web/pages/register.vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    meta: register0VfGnzk9qVMeta || {},
    component: () => import("D:/dev_web/pages/register.vue")
  },
  {
    name: "register___vi",
    path: "/vi/register",
    meta: register0VfGnzk9qVMeta || {},
    component: () => import("D:/dev_web/pages/register.vue")
  },
  {
    name: "register___cn",
    path: "/cn/register",
    meta: register0VfGnzk9qVMeta || {},
    component: () => import("D:/dev_web/pages/register.vue")
  },
  {
    name: "reset-password___en___default",
    path: "/reset-password",
    meta: reset_45passwordOUzA4hn5xlMeta || {},
    component: () => import("D:/dev_web/pages/reset-password.vue")
  },
  {
    name: "reset-password___en",
    path: "/en/reset-password",
    meta: reset_45passwordOUzA4hn5xlMeta || {},
    component: () => import("D:/dev_web/pages/reset-password.vue")
  },
  {
    name: "reset-password___vi",
    path: "/vi/reset-password",
    meta: reset_45passwordOUzA4hn5xlMeta || {},
    component: () => import("D:/dev_web/pages/reset-password.vue")
  },
  {
    name: "reset-password___cn",
    path: "/cn/reset-password",
    meta: reset_45passwordOUzA4hn5xlMeta || {},
    component: () => import("D:/dev_web/pages/reset-password.vue")
  },
  {
    name: "shop-slug___en___default",
    path: "/shop/:slug(.*)*",
    meta: _91_46_46_46slug_93x9JDj0h6hSMeta || {},
    component: () => import("D:/dev_web/pages/shop/[...slug].vue")
  },
  {
    name: "shop-slug___en",
    path: "/en/shop/:slug(.*)*",
    meta: _91_46_46_46slug_93x9JDj0h6hSMeta || {},
    component: () => import("D:/dev_web/pages/shop/[...slug].vue")
  },
  {
    name: "shop-slug___vi",
    path: "/vi/shop/:slug(.*)*",
    meta: _91_46_46_46slug_93x9JDj0h6hSMeta || {},
    component: () => import("D:/dev_web/pages/shop/[...slug].vue")
  },
  {
    name: "shop-slug___cn",
    path: "/cn/shop/:slug(.*)*",
    meta: _91_46_46_46slug_93x9JDj0h6hSMeta || {},
    component: () => import("D:/dev_web/pages/shop/[...slug].vue")
  },
  {
    name: "transfer-log___en___default",
    path: "/transfer-log",
    meta: transfer_45logi0KJubykB0Meta || {},
    component: () => import("D:/dev_web/pages/transfer-log.vue")
  },
  {
    name: "transfer-log___en",
    path: "/en/transfer-log",
    meta: transfer_45logi0KJubykB0Meta || {},
    component: () => import("D:/dev_web/pages/transfer-log.vue")
  },
  {
    name: "transfer-log___vi",
    path: "/vi/transfer-log",
    meta: transfer_45logi0KJubykB0Meta || {},
    component: () => import("D:/dev_web/pages/transfer-log.vue")
  },
  {
    name: "transfer-log___cn",
    path: "/cn/transfer-log",
    meta: transfer_45logi0KJubykB0Meta || {},
    component: () => import("D:/dev_web/pages/transfer-log.vue")
  },
  {
    name: "transfer___en___default",
    path: "/transfer",
    meta: transfer3PtB9qZVgGMeta || {},
    component: () => import("D:/dev_web/pages/transfer.vue")
  },
  {
    name: "transfer___en",
    path: "/en/transfer",
    meta: transfer3PtB9qZVgGMeta || {},
    component: () => import("D:/dev_web/pages/transfer.vue")
  },
  {
    name: "transfer___vi",
    path: "/vi/transfer",
    meta: transfer3PtB9qZVgGMeta || {},
    component: () => import("D:/dev_web/pages/transfer.vue")
  },
  {
    name: "transfer___cn",
    path: "/cn/transfer",
    meta: transfer3PtB9qZVgGMeta || {},
    component: () => import("D:/dev_web/pages/transfer.vue")
  }
]